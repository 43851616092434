export function appendAdobeDataAnalytics(nodePath: string, location: Location, initial: boolean) {
    window.adobe_dtm_data = window.adobe_dtm_data || {
        "event": {
            "lastclickedarea": "",
            "lastaction": "",
            "lastclickedelement": "",
            "beneficiary": "",
            "subsection": "",
            "prevpage": "",
            "PageName": "",
            "pageUrl": "",
            "pageLevel": "",
            "utmSource": "",
            "utmMedium": "",
            "utmContent": "",
            "utmTerm": "",
            "utmCampaign": ""
        }
    };
    const {
        host,
        search,
        href
    } = location;
    const searchParams: { [key: string]: string; } = {};
    search.substr(1).split('&').forEach(paramPair => {
        const [key, value] = paramPair.split('=');
        searchParams[key] = value;
    });
    const prevpage = window.adobe_dtm_data.event.PageName;
    const nodePathSplit = nodePath.substr(1).split('/');
    const prefixCode = host.includes('esg') ? 'E' : 'V';

    window.adobe_dtm_data = {
        "event": {
            "lastclickedarea": nodePathSplit[0] || "",
            "lastaction": nodePathSplit[1] || "",
            "lastclickedelement": nodePathSplit[2] || "",
            "beneficiary": nodePathSplit[3] || "",
            "subsection": nodePathSplit[4] || "",
            "prevpage": initial ? "" : prevpage,
            "PageName": `${prefixCode}:${nodePath.substr(1)}`,
            "pageUrl": href,
            "pageLevel": nodePathSplit[0] ? `Level${nodePathSplit.length}` : "",
            "utmSource": searchParams.utm_source || "",
            "utmMedium": searchParams.utm_medium || "",
            "utmContent": searchParams.utm_content || "",
            "utmTerm": searchParams.utm_term || "",
            "utmCampaign": searchParams.utm_campaign || ""
        }
    };
}


export function adobeAnalytics() {
    if (window._satellite && window._satellite.track) {
        window._satellite.track("PAGE_VORTAL");
    }
}
