import Router, { CMSLocation, LocationChangeCallback, LocationService, PrerenderingLocationChangeCallback } from "@cms/core/lib/router";
import {
    addListenerAnalyticScrollDepth,
    analyticsDataLayer,
    analyticsDataLayerConsent, analyticsDataLayerYT,
    createDataLayerPushTimer
} from "./analytics-data-layer";
import { adobeAnalytics, appendAdobeDataAnalytics } from "./analytics-adobe";
import { createCssVarForIE} from "./css_variables_ie11";
import { getCookieValue, handleListenForGDPRCookieChange } from "@cms/utils/lib/cookie";
import { ServedContentType} from "@cms/api-adapter/lib/content";

// tslint:disable-next-line: max-line-length
export const prerenderingCallback: PrerenderingLocationChangeCallback = (contentType: ServedContentType, currentLocation: CMSLocation): Promise<void> => {
    const {
        nodePath,
        location
    } = currentLocation;

    if (contentType !== ServedContentType.PageNotFound && contentType !== ServedContentType.Redirect) {
        appendAnalytics(nodePath, location, true);
    }
    return Promise.resolve();
};

// tslint:disable-next-line: max-line-length
export const locationChange: LocationChangeCallback = (replaceContentPromise: Promise<ServedContentType>, targetLocation: CMSLocation): Promise<any> => {
    const {
        nodePath,
        location
    } = targetLocation;

    if (replaceContentPromise) {
        return replaceContentPromise.then((type) => {
            if (type !== ServedContentType.PageNotFound && type !== ServedContentType.Redirect) {
                appendAnalytics(nodePath, location, false);
                resetStorageScrollDepth();
            }
        });
    }
    return replaceContentPromise;
};

function appendAnalytics(nodePath: string, location: Location, initial: boolean): void {
    analyticsDataLayerConsent();
    analyticsDataLayerYT();
    appendAdobeDataAnalytics(nodePath, location, initial);
    adobeAnalytics();
    analyticsDataLayer();
    dataLayerPushTimer();
}

export const isCookieAccepted = (): boolean => {
    return getCookieValue("cookiePolicyGDPR") === "2";
};


export const isIEOrEdge = () => {
    var ua = navigator.userAgent;
    return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1 || checkIfEdgeOld(ua);
}

//edgeHTML version 15.15 is for Edge 40
function checkIfEdgeOld(ua: string) {
    const edgeVersion = ua.match(/Edge\/(\d+)/);
    if (edgeVersion && edgeVersion[0]) {
        const version = edgeVersion[0].match(/\d+/);
        return Number(version) < 15;
    }
    return false;
}

export const loadScript = (scriptSrc: string) => {
    var scriptElement = document.createElement('script');
    scriptElement.src = scriptSrc;
    scriptElement.type = 'text/javascript';
    document.querySelector('head').appendChild(scriptElement);
}

const locationService: LocationService = new LocationService();

const dataLayerPushTimer = createDataLayerPushTimer();

const resetStorageScrollDepth = addListenerAnalyticScrollDepth();

createCssVarForIE();
handleListenForGDPRCookieChange();

new Router(locationService, locationChange, prerenderingCallback, isCookieAccepted);
