import { getCookieValue } from "@cms/utils/lib/cookie";

function gtm_c(c_aam: string):number {
    var c_aam=c_aam.substring(21,38);
    var h_aam = 0;
    if (c_aam.length == 0) {
        return h_aam;
    }
    for (var i = 0; i < c_aam.length; i++) {
        var char = c_aam.charCodeAt(i);
        h_aam = ((h_aam<<5)-h_aam)+char;
        h_aam = h_aam & h_aam;
    }
    h_aam = h_aam >>> 0;
    return h_aam;
}

 export const gtm_aam = ():any => {
     const COOKIE_AAM_UUID = "aam_uuid";
     const COOKIE_POLICY_GDPR = "cookiePolicyGDPR";
     if (getCookieValue(COOKIE_AAM_UUID) && getCookieValue(COOKIE_POLICY_GDPR) === "2") {
         return gtm_c(getCookieValue(COOKIE_AAM_UUID));
     }
     return null;
 }
